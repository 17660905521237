/* styles.css */
body {
    background-color: #222222; /* Dark gray background */
    color: #ff9800; /* Orange text */
    font-family: 'Poppins', sans-serif; /* Modern font */
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  /* Buttons */
  button {
    background-color: #ff9800; /* Orange button */
    color: #222;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
  }
  
  button:hover {
    background-color: #e68900; /* Slightly darker orange on hover */
  }
  
/* Footer link styling */
.footer-link {
  color: #ff9800; /* Orange link */
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  margin-top: 10px;
}

.footer-link:hover {
  text-decoration: underline;
  color: #e68900; /* Darker orange on hover */
}